"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var ProductList_1 = require("./ProductList");
var BrandList_1 = require("./BrandList");
var ProductDisplay_1 = require("./ProductDisplay");
var Product_1 = require("./Models/Product");
var Brand_1 = require("./Models/Brand");
var Category_1 = require("./Models/Category");
var Store_1 = require("./Store");
require("jquery");
var ProductsApp = /** @class */ (function (_super) {
    __extends(ProductsApp, _super);
    function ProductsApp(props) {
        var _this = _super.call(this, props) || this;
        Store_1.default.containerId = _this.props.containerId;
        Store_1.default.currentPageName = _this.props.currentPageName;
        Store_1.default.containerUrlSegment = _this.props.containerUrlSegment;
        Store_1.default.Translations = _this.props.translations;
        Store_1.default.BackgroundImageUrl = _this.props.backgroundImageUrl;
        Store_1.default.DimFilter = _this.props.dimFilter || false;
        Store_1.default.currentLanguage = _this.props.currentLanguage;
        Store_1.default.disableBrands = _this.props.disableBrands || false;
        Store_1.default.disableCategories = _this.props.disableCategories || false;
        if (_this.props.selectedProduct !== null) {
            Store_1.default.SelectedProduct = new Product_1.default({ id: _this.props.selectedProduct });
            Store_1.default.SelectedProduct.loadDetails();
            Store_1.default.loadBrands();
            Store_1.default.loadCategories();
        }
        if (_this.props.selectedBrand !== null) {
            Store_1.default.SelectedBrand = new Brand_1.default(_this.props.selectedBrand);
            Store_1.default.loadProducts();
        }
        else if (_this.props.selectedCategories !== null && _this.props.selectedCategories.length > 0) {
            Store_1.default.SelectedCategories.replace(_this.props.selectedCategories.map(function (json) { return new Category_1.default(json); }));
            Store_1.default.loadProducts();
        }
        window.onpopstate = function () {
            location.reload();
        };
        return _this;
    }
    ProductsApp.prototype.render = function () {
        return (React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement(react_router_dom_1.Switch, null,
                React.createElement(react_router_dom_1.Route, { exact: true, path: Store_1.default.containerUrlSegment, component: Store_1.default.disableBrands ? ProductList_1.default : BrandList_1.default }),
                Store_1.default.disableBrands && React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":categories/:product", component: ProductDisplay_1.default }),
                Store_1.default.disableBrands && Store_1.default.disableCategories && React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":product", component: ProductDisplay_1.default }),
                React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":categories/:brand/:product", component: ProductDisplay_1.default }),
                React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":categories", component: ProductList_1.default }))));
    };
    return ProductsApp;
}(React.Component));
exports.default = ProductsApp;
