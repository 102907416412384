import 'jquery';

$(document).ready(function () {
    (function () {
        const imgs = $('img[data-scaling]');
        imgs.each(function (i) {
            const parentScaling = $(this).attr('data-parent-scaling');

            let src = "";
            if (parentScaling !== undefined && parentScaling) {
                src = $(this).attr('data-scaling') + '?width=' + Math.ceil($(this).parent().width() / 100) * 100;
            }
            else {
                src = $(this).attr('data-scaling') + '?width=' + Math.ceil($(window).width() / 100) * 100;
            }

            $(this).attr('src', src);
            $(this).removeAttr('data-scaling');
        });

        var bgs = $('*[data-scaling-bg]');
        bgs.each(function (i) {
            var parentScaling = $(this).attr('data-parent-scaling');
            const height = $(this).innerHeight();
            const width = $(this).width();

            if (parentScaling != undefined && parentScaling) {
                var src = $(this).attr('data-scaling-bg') + '?width=' + Math.ceil($(this).parent().width() / 100) * 100;
            }
            else {
                //TODO test if this fixes image quality for image content block and article images. This issue also affects ContentArea.tsx
                var src = (width > height) ?
                    $(this).attr('data-scaling-bg') + '?width=' + Math.ceil(width / 100) * 100 :
                    $(this).attr('data-scaling-bg') + '?height=' + Math.ceil(height / 100) * 100
            }

            $(this).css('background-image', 'url(' + src + ')');
            $(this).removeAttr("data-scaling-bg");
        })
    })();
});
