"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Brand_1 = require("./Models/Brand");
var Category_1 = require("./Models/Category");
var Product_1 = require("./Models/Product");
require("jquery");
var Cookies = require("js-cookie");
var Subcategory_1 = require("./Models/Subcategory");
var Store = /** @class */ (function () {
    function Store() {
        this.Brands = null;
        this.Products = null;
        this.Categories = null;
        this.SubNavs = null;
        this.SelectedBrand = null;
        this.SelectedProduct = null;
        this.SelectedCategories = mobx_1.observable([]);
        this.SelectedSubNav = null;
        this.currentPageName = "";
        this.disableBrands = false;
        this.disableCategories = false;
        this.navigationColor = "";
        this.siteName = "";
        this.currentLanguage = "";
        this.containerId = 0;
        this.containerUrlSegment = "";
        this.containerName = "";
        this._scrollspeed = 200;
        //V2
        this.SelectedCategory = null;
        this.Subcategories = null;
        this.SelectedSubcategory = null;
        this.readMoreLink = null;
        this.loading = false;
        this.BackgroundImageUrl = null;
        this.DimFilter = false;
    }
    Store.prototype.loadBrands = function () {
        this.loading = true;
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetBrands",
            data: {
                "id": this.containerId,
                "language": store.currentLanguage
            },
            success: function (data) {
                this.Brands = data.map(function (json) { return new Brand_1.default(json); });
                this.loading = false;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.loadBrandsByCategory = function () {
        this.loading = true;
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetBrandsByCategory",
            data: {
                "id": this.SelectedCategory.id,
                "language": store.currentLanguage
            },
            success: function (data) {
                this.Brands = data.map(function (json) { return new Brand_1.default(json); });
                this.loading = false;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.loadSubcategories = function () {
        this.loading = true;
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetSubcategories",
            data: {
                "id": this.SelectedCategory.id,
                "language": store.currentLanguage
            },
            success: function (data) {
                this.Subcategories = data.map(function (json) { return new Subcategory_1.default(json); });
                this.loading = false;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.validateAge = function () {
        $(".o-age-validation").show();
        $(".o-age-validation, .black-filter").css({
            top: 0
        });
        $("#o-age-validation-wrapper").slideDown(100);
    };
    Store.prototype.loadCategories = function () {
        this.loading = true;
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetProductCategories",
            data: {
                "id": this.containerId,
                "language": store.currentLanguage
            },
            success: function (data) {
                this.Categories = data.map(function (json) { return new Category_1.default(json); });
                this.loading = false;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.loadProducts = function () {
        this.loading = true;
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetProducts",
            data: {
                "id": this.containerId,
                "categories": this.SelectedCategories !== null ? this.SelectedCategories.map(function (x) { return x.id; }) : [],
                "brands": this.SelectedBrand !== null ? [this.SelectedBrand.id] : [],
                "subcategory": this.SelectedSubNav !== null ? this.SelectedSubNav.name : null,
                "subcategoryId": this.SelectedSubcategory != null ? this.SelectedSubcategory.id : null,
                "language": this.currentLanguage
            },
            success: function (data) {
                this.Products = data.map(function (json) { return new Product_1.default(json); });
                this.loading = false;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.loadSubNav = function () {
        //var data: Array<any> = [{ name: "test", url: "test" }, { name: "test2", url: "test2" }];
        //this.SubNavs = observable(data.map(json => new SubNav(json)));
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetCategorySubNavs",
            data: {
                "id": (this.SelectedCategories !== null && this.SelectedCategories.length > 0) ? this.SelectedCategories.map(function (x) { return x.id; })[0] : 0,
                "language": this.currentLanguage
            },
            success: function (data) {
                this.SubNavs = data.map(function (json) { return new Product_1.default({ name: json, url: "test" }); });
                //this.loading = false;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.trackPageView = function (product) {
        $.ajax({
            type: "Get",
            url: "/TrackingApi/TrackView",
            data: {
                "contentId": product.id,
                "language": this.currentLanguage
            },
            processData: true,
            success: function (data) {
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    Store.prototype.updateNavigationColor = function (id) {
        $.ajax({
            type: "Get",
            url: "/LayoutApi/GetPageNavigationColor",
            data: {
                "contentId": id
            },
            processData: true,
            success: function (data) {
                $('.inline-styles').text('nav.o-navigation>div a, nav.o-navigation>div a:hover {color: ' + data + ' !important;}nav.o-navigation>div a:hover, nav.o-navigation>div a.active {border-bottom: 2px solid ' + data + ';}.logo-fill {fill: ' + data + ' !important;}.o-mobile-menu:not(.open) .menu-icon span{background: ' + data + ' !important;}.o-mobile-menu:not(.open) .main-search-button, .o-mobile-menu:not(.open) .main-language-button {color: ' + data + ' !important;}');
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    __decorate([
        mobx_1.observable
    ], Store.prototype, "Brands", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "Products", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "Categories", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SubNavs", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SelectedBrand", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SelectedProduct", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SelectedCategories", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SelectedSubNav", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "currentPageName", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "disableBrands", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "disableCategories", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "navigationColor", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "siteName", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SelectedCategory", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "Subcategories", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "SelectedSubcategory", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "readMoreLink", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "loading", void 0);
    return Store;
}());
var store = new Store();
mobx_1.reaction(function () { return store.SelectedBrand; }, function () {
    if (store.SelectedBrand !== null && store.SelectedBrand.k18 && Cookies.get("adult") !== "true") {
        store.validateAge();
    }
});
mobx_1.reaction(function () { return store.SelectedProduct; }, function () {
    if (store.SelectedProduct !== null && store.SelectedProduct.k18 && Cookies.get("adult") !== "true") {
        store.validateAge();
    }
});
exports.default = store;
