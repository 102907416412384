"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var Store_1 = require("../Store");
var SearchResultsInfo = /** @class */ (function (_super) {
    __extends(SearchResultsInfo, _super);
    function SearchResultsInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchResultsInfo.prototype.render = function () {
        var resultText = Store_1.default.results && Store_1.default.results.total > 0 ? Store_1.default.translations.resultsFound : Store_1.default.translations.noResultsFound;
        return React.createElement("p", null, resultText.replace("$0", Store_1.default.previousQuery).replace("$1", Store_1.default.results.total.toString()));
    };
    SearchResultsInfo = __decorate([
        mobx_react_1.observer
    ], SearchResultsInfo);
    return SearchResultsInfo;
}(React.Component));
exports.default = SearchResultsInfo;
