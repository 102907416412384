"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var Store_1 = require("../Store");
var mobx_1 = require("mobx");
var SearchPagination = /** @class */ (function (_super) {
    __extends(SearchPagination, _super);
    function SearchPagination() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SearchPagination.prototype, "currentPage", {
        get: function () {
            return Store_1.default.skip / Store_1.default.take + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchPagination.prototype, "numberOfPages", {
        get: function () {
            return Math.ceil(Store_1.default.results.total / Store_1.default.take);
        },
        enumerable: true,
        configurable: true
    });
    SearchPagination.prototype.render = function () {
        var _this = this;
        var pagination = [];
        for (var i = 1; i <= this.numberOfPages; i++) {
            pagination.push(React.createElement("span", { key: i, className: this.currentPage === i ? "search-pagination-active" : "", onClick: Store_1.default.ChangePage.bind(this, i) },
                " ",
                i));
        }
        return (React.createElement("div", { className: "search-pagination" },
            this.currentPage > 1 && React.createElement("span", { onClick: function (e) { return Store_1.default.ChangePage(_this.currentPage - 1); } }, "\u00AB"),
            pagination,
            this.currentPage < (this.numberOfPages) && React.createElement("span", { onClick: function (e) { return Store_1.default.ChangePage(_this.currentPage + 1); } }, "\u00BB")));
    };
    __decorate([
        mobx_1.computed
    ], SearchPagination.prototype, "currentPage", null);
    __decorate([
        mobx_1.computed
    ], SearchPagination.prototype, "numberOfPages", null);
    SearchPagination = __decorate([
        mobx_react_1.observer
    ], SearchPagination);
    return SearchPagination;
}(React.Component));
exports.default = SearchPagination;
