"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Category = /** @class */ (function () {
    function Category(json) {
        this.id = 0;
        this.name = "";
        this.urlSegment = "";
        this.url = "";
        this.categoryHeader = "";
        this.categoryIngress = "";
        this.backgroundImageUrl = "";
        this.dimFilter = false;
        this.listingImageUrl = "";
        this.readMoreLink = "";
        this.subcategoriesText = "";
        this.brandsText = "";
        this.readMoreLinkText = "";
        this.numberOfProducts = 0;
        this.id = json.id;
        this.name = json.name;
        this.urlSegment = json.urlSegment;
        this.url = json.url;
        this.categoryHeader = json.categoryHeader;
        this.categoryIngress = json.categoryIngress;
        this.backgroundImageUrl = json.backgroundImageUrl;
        this.dimFilter = json.dimFilter;
        this.listingImageUrl = json.listingImageUrl;
        this.readMoreLink = json.readMoreLink;
        this.subcategoriesText = json.subcategoriesText;
        this.brandsText = json.brandsText;
        this.readMoreLinkText = json.readMoreLinkText;
        this.numberOfProducts = json.numberOfProducts;
    }
    return Category;
}());
exports.default = Category;
