import 'jquery';
import 'slick-carousel';



function startStrips() {
    $(document).on('click', function (e) {
        let strip = $('.strip_block');
        let block = strip.parent();
        let popups = block.find('.strip_popup');
        popups.remove();
        let expanders = block.find('.strip_expander');
        expanders.show();
    });
    $(document).on('click', '.strip_links', function (e) {
        e.stopPropagation();
        $(document).click();
        let element = $(this);
        let linkDiv = element.find('.strip_links_popup').clone();
        let strip = element.closest('.strip_block');
        let block = strip.parent();
        let expander = element.find('.strip_expander');
        expander.hide();

        let popup = block.find('.strip_popup');
        if (popup.length == 0) {
            popup = $('<div>').addClass('strip_popup');
            block.append(popup);
        }
        popup.replaceWith(linkDiv);
        let blockRect = block[0].getBoundingClientRect();
        let elementRect = element[0].getBoundingClientRect();
        let x = elementRect.x - blockRect.x;
        let y = elementRect.y - blockRect.y + 50;
        linkDiv.css('left', x);
        linkDiv.css('top', y);
        linkDiv.width(element.width());
        linkDiv.removeClass('strip_links_popup').addClass('strip_popup');
    });
}

function stripResize() {
    let mobile = $(window).width() <= 640;
    $('.strip_image video').each((i, e) => {
        let element = $(e);
        let src = mobile ? element.attr('data-src-mobile') : element.attr('data-src-desktop');
        let source = element.find('source');
        let old = source.attr('src');
        if (src !== old) {
            let old = source.attr('src', src);
            e.load();
        }
    });
}

$(() => {
    startStrips();

    setInterval(() => {

        $('.strip_block.loading').each((i, o) => {
            let element = $(o);
            let panels = element.find('.strip_panels');
            let dataHeight = element.attr('data-image-height');
            let height = parseInt(dataHeight, 10);
            if (!isNaN(height) && height > 0) {
                panels.find('img,video').css('height', height + 'px');
                let videos = panels.find('video');
                videos.each((index, video) => {
                    video.load();
                });
            }
            panels.slick();
            element.removeClass('loading');
            stripResize();
        });

    }, 1000);

    $(window).on('resize', stripResize);
});
