"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var Filter_1 = require("./Filter");
var Store_1 = require("../Store");
var Filters = /** @class */ (function (_super) {
    __extends(Filters, _super);
    function Filters(props) {
        var _this = _super.call(this, props) || this;
        !Store_1.default.disableBrands ? Store_1.default.loadBrands() : Store_1.default.loadProducts();
        Store_1.default.loadCategories();
        return _this;
    }
    Filters.prototype.OnBrandSelect = function (brand) {
        Store_1.default.SelectedBrand = brand;
        Store_1.default.SelectedCategories.replace([]);
        Store_1.default.loadProducts();
    };
    Filters.prototype.OnCategorySelect = function (categories) {
        Store_1.default.SelectedBrand = null;
        Store_1.default.SelectedCategories.replace(Array.isArray(categories) ? categories.map(function (x) { return x; }) : [categories]);
        Store_1.default.loadProducts();
    };
    Filters.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("h1", { className: "u-tc s-h1" }, Store_1.default.currentPageName),
            React.createElement("div", { className: "o-filters row" },
                !Store_1.default.disableBrands &&
                    React.createElement(Filter_1.default, { allLink: Store_1.default.containerUrlSegment, allOnSelect: function () { return _this.OnBrandSelect(null); }, name: Store_1.default.SelectedBrand !== null ? Store_1.default.SelectedBrand.name : Store_1.default.Translations.brandsText, onSelect: this.OnBrandSelect.bind(this), selectedFilters: Store_1.default.SelectedBrand !== null ? [Store_1.default.SelectedBrand] : null, filters: Store_1.default.Brands, active: Store_1.default.SelectedBrand !== null || (Store_1.default.SelectedBrand === null && (Store_1.default.SelectedCategories === null || Store_1.default.SelectedCategories.length < 1)) }),
                !Store_1.default.disableCategories &&
                    React.createElement(Filter_1.default, { allLink: Store_1.default.containerUrlSegment + "kaikki", allOnSelect: function () { return _this.OnCategorySelect([{ name: "Kaikki", id: 0, urlSegment: "kaikki" }]); }, name: Store_1.default.Translations.productTypesText, onSelect: this.OnCategorySelect, onClear: function () { return _this.OnCategorySelect([]); }, multiselect: !Store_1.default.disableBrands, selectedFilters: Store_1.default.SelectedCategories, filters: Store_1.default.Categories, active: Store_1.default.SelectedCategories !== null && Store_1.default.SelectedCategories.length > 0 }))));
    };
    __decorate([
        mobx_1.action
    ], Filters.prototype, "OnBrandSelect", null);
    __decorate([
        mobx_1.action
    ], Filters.prototype, "OnCategorySelect", null);
    Filters = __decorate([
        mobx_react_1.observer
    ], Filters);
    return Filters;
}(React.Component));
exports.default = Filters;
