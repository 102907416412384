"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var Store_1 = require("../Store");
var BrandBox = /** @class */ (function (_super) {
    __extends(BrandBox, _super);
    function BrandBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BrandBox.prototype.onClick = function () {
        Store_1.default.SelectedBrand = this.props;
        Store_1.default.loadProducts();
        $([document.documentElement, document.body]).animate({
            scrollTop: 0
        }, Store_1.default._scrollspeed);
    };
    BrandBox.prototype.render = function () {
        return (React.createElement(react_router_dom_1.Link, { to: this.props.url, onClick: this.onClick, className: "o-product-brand-wrapper col-md-4 col-sm-6 col-xs-6" },
            React.createElement("div", { style: { backgroundImage: "url(" + this.props.listingImageUrl + "?height=800&quality=70" + ")" }, className: "o-product-brand-container" },
                React.createElement("div", { className: "filter" }),
                React.createElement("h3", null, this.props.name))));
    };
    __decorate([
        mobx_1.action.bound
    ], BrandBox.prototype, "onClick", null);
    BrandBox = __decorate([
        mobx_react_1.observer
    ], BrandBox);
    return BrandBox;
}(React.Component));
exports.default = BrandBox;
