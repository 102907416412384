"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var react_custom_scrollbars_1 = require("react-custom-scrollbars");
var Store_1 = require("../Store");
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(props) {
        var _this = _super.call(this, props) || this;
        _this.open = false;
        _this.selectedBuffer = mobx_1.observable([]);
        _this.node = null;
        mobx_1.autorun(function () {
            if (this.props.selectedFilters !== null) {
                this.selectedBuffer.replace(this.props.selectedFilters.slice());
            }
        }.bind(_this));
        return _this;
    }
    Filter.prototype.componentWillMount = function () {
        document.addEventListener('mousedown', this.handleClick.bind(this), false);
    };
    Filter.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClick.bind(this), false);
    };
    Filter.prototype.toggleOpen = function () {
        this.open = !this.open;
    };
    Filter.prototype.Clear = function () {
        this.selectedBuffer.replace([]);
        this.props.onClear();
    };
    Filter.prototype.handleClick = function (e) {
        if (this.node !== null && !this.node.contains(e.target)) {
            this.open = false;
        }
    };
    Filter.prototype.onSelect = function (filter) {
        if (!this.props.multiselect) {
            this.toggleOpen();
            this.props.onSelect(filter);
        }
        else {
            var index = -1;
            if (this.selectedBuffer !== null) {
                index = this.selectedBuffer.map(function (x) { return x.id; }).indexOf(filter.id);
            }
            if (index > -1) {
                this.selectedBuffer.splice(index, 1);
            }
            else if (index < 0) {
                this.selectedBuffer.push(filter);
            }
            // We remove all selection from buffer if we select something else
            if (filter.id !== 0) {
                var allindex = this.selectedBuffer.map(function (x) { return x.id; }).indexOf(0);
                if (allindex > -1) {
                    this.selectedBuffer.splice(allindex, 1);
                }
            }
        }
    };
    Filter.prototype.renderThumb = function () {
        return (React.createElement("div", { className: "o-scrollthumb" }));
    };
    Object.defineProperty(Filter.prototype, "getUrl", {
        get: function () {
            if (this.selectedBuffer === null) {
                return Store_1.default.containerUrlSegment;
            }
            return Store_1.default.containerUrlSegment + this.selectedBuffer.map(function (x) { return x.urlSegment; }).join(".");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "displayName", {
        get: function () {
            if (this.props.multiselect) {
                return this.props.name + (this.selectedBuffer.length > 0 ? " (" + this.selectedBuffer.length + ")" : "");
            }
            else {
                return this.selectedBuffer !== null && this.selectedBuffer.length > 0 ? this.selectedBuffer[0].name : this.props.name;
            }
        },
        enumerable: true,
        configurable: true
    });
    Filter.prototype.Show = function () {
        this.props.onSelect(this.selectedBuffer.toJS());
        this.toggleOpen();
    };
    Filter.prototype.onAllSelect = function () {
        this.open = false;
        this.props.allOnSelect();
    };
    Filter.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "col-md-3 col-sm-6 col-xs-12" },
            React.createElement("div", { ref: function (node) { return _this.node = node; }, className: "o-product-filter " + (this.props.active ? "active" : "") },
                React.createElement("div", { className: "o-product-filter-head", onClick: this.toggleOpen },
                    React.createElement("span", null, this.displayName),
                    React.createElement("span", { className: "arrow " + (this.open ? "flipped" : "") }, '\u25BC')),
                React.createElement("div", { className: "o-product-filter-options " + (this.open ? "open " : "") + (this.props.multiselect ? "multiselect " : "") },
                    React.createElement(react_custom_scrollbars_1.default, { autoHeight: true, autoHeightMax: 500, renderThumbVertical: this.renderThumb, renderTrackVertical: function () { return React.createElement("div", { className: "o-scrollthumb-track" }); } },
                        React.createElement("div", { className: "options" },
                            React.createElement(react_router_dom_1.Link, { to: this.props.allLink, onClick: this.onAllSelect }, Store_1.default.Translations.allText),
                            this.props.filters && this.props.filters.map(function (filter, index) {
                                var active = false;
                                if (_this.selectedBuffer !== null) {
                                    active = _this.selectedBuffer.some(function (x) { return x.id === filter.id; });
                                }
                                if (_this.props.multiselect) {
                                    return (React.createElement("div", { onClick: _this.onSelect.bind(_this, filter), className: active ? "active" : "", key: index },
                                        React.createElement("span", null,
                                            active ? '\u25CF' : '\u25CB',
                                            " "),
                                        filter.name));
                                }
                                else {
                                    return (React.createElement(react_router_dom_1.Link, { to: filter.url, onClick: _this.onSelect.bind(_this, filter), key: index }, filter.name));
                                }
                            }))),
                    this.props.multiselect &&
                        React.createElement("div", { className: "o-product-filter-controls" },
                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.containerUrlSegment, onClick: this.Clear.bind(this) }, Store_1.default.Translations.clearText),
                            React.createElement(react_router_dom_1.Link, { to: this.getUrl, onClick: this.Show.bind(this) }, Store_1.default.Translations.showText))))));
    };
    __decorate([
        mobx_1.observable
    ], Filter.prototype, "open", void 0);
    __decorate([
        mobx_1.observable
    ], Filter.prototype, "selectedBuffer", void 0);
    __decorate([
        mobx_1.action.bound
    ], Filter.prototype, "toggleOpen", null);
    __decorate([
        mobx_1.action.bound
    ], Filter.prototype, "Clear", null);
    __decorate([
        mobx_1.action.bound
    ], Filter.prototype, "handleClick", null);
    __decorate([
        mobx_1.action.bound
    ], Filter.prototype, "onSelect", null);
    __decorate([
        mobx_1.computed
    ], Filter.prototype, "getUrl", null);
    __decorate([
        mobx_1.computed
    ], Filter.prototype, "displayName", null);
    __decorate([
        mobx_1.action.bound
    ], Filter.prototype, "onAllSelect", null);
    Filter = __decorate([
        mobx_react_1.observer
    ], Filter);
    return Filter;
}(React.Component));
exports.default = Filter;
