"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("jquery");
var LyytiEvent_1 = require("./Models/LyytiEvent");
var LyytiCalendarApp = /** @class */ (function (_super) {
    __extends(LyytiCalendarApp, _super);
    function LyytiCalendarApp(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            loading: false,
            error: false,
            events: [],
            maximumMonthsDisplayed: 3
        };
        return _this;
    }
    LyytiCalendarApp.prototype.componentDidMount = function () {
        this.loadEvents();
    };
    /**
     * Increases the number months displayed
     */
    LyytiCalendarApp.prototype.showMoreEvents = function (additionalMonthsToDisplay) {
        if (additionalMonthsToDisplay === void 0) { additionalMonthsToDisplay = 3; }
        this.setState({ maximumMonthsDisplayed: (this.state.maximumMonthsDisplayed + additionalMonthsToDisplay) });
    };
    /**
     * Requests events from LyytiApiController. Events are sorted based on their start date and saved to the state.
     */
    LyytiCalendarApp.prototype.loadEvents = function () {
        var _this = this;
        this.setState({
            loading: true
        }, function () {
            $.ajax({
                type: "GET",
                url: "/api/LyytiApi/getevents",
                data: {},
                success: function (data) {
                    if (!data.results) {
                        this.setState({ error: true });
                        return;
                    }
                    var results = data.results;
                    var events = [];
                    Object.keys(results).forEach(function (key) {
                        events.push(new LyytiEvent_1.default(results[key]));
                    });
                    events.sort(function (event_a, event_b) { return event_a.start_time_utc - event_b.start_time_utc; });
                    this.setState({ loading: false, events: events });
                }.bind(_this),
                error: function (err) {
                    this.setState({
                        error: true,
                        loading: false,
                    });
                }.bind(_this)
            });
        });
    };
    /**
     * Iterates over the events to create content.
     * Displays alternative content if events are still being loaded or if there are no upcoming events or if events couldn't be loaded.
     */
    LyytiCalendarApp.prototype.render = function () {
        if (this.state.error === true)
            return (React.createElement("div", { dangerouslySetInnerHTML: { __html: this.props.loadingEventsFailed } }));
        if (this.state.loading === true)
            return (React.createElement("div", { dangerouslySetInnerHTML: { __html: this.props.loadingEvents } }));
        if (this.state.events.length === 0)
            return (React.createElement("div", { dangerouslySetInnerHTML: { __html: this.props.noUpcomingEvents } }));
        /**
         * Main content to be rendered
         */
        var content = [];
        /**
         * Date object to store the previous month (and year) when splitting the events by the start date
         */
        var previousDate = new Date();
        previousDate.setMonth(previousDate.getMonth() - 1);
        /**
         * Index of the background color used. loops so that events of different months get different background color
         */
        var backgroundColor = 0;
        /**
         * Background colors used to fill the event box elements
         */
        var backgroundColors = ["s-themebgcolor-2", "s-themebgcolor-4"];
        var monthsDisplayed = 0;
        var eventsDisplayed = 0;
        // Iterate over the the events
        for (var _i = 0, _a = this.state.events; _i < _a.length; _i++) {
            var event_1 = _a[_i];
            eventsDisplayed++;
            // Create a new heading element to separate events that occurs in the next month
            if (previousDate.getMonth() !== event_1.startDate.getMonth() || previousDate.getFullYear() !== event_1.startDate.getFullYear()) {
                monthsDisplayed++;
                if (monthsDisplayed > this.state.maximumMonthsDisplayed)
                    break; // Break the iteration if the limit for visible months is met
                previousDate = event_1.startDate;
                backgroundColor++;
                if (backgroundColor >= backgroundColors.length)
                    backgroundColor = 0;
                content.push(React.createElement("div", { key: 'month-' + event_1.start_time_utc, className: "col-xs-12 u-mb-2" },
                    React.createElement("h2", { className: "s-h2 u-tc" }, event_1.getHeaderForMonth())));
            }
            // Create the actual element representing the event
            content.push(React.createElement("div", { key: event_1.id, className: "col-lg-3 col-sm-3 col-xs-12" },
                React.createElement("a", { target: "_blank", href: event_1.enrollment_url },
                    React.createElement("div", { className: "o-lyyticalendar-box" + " " + backgroundColors[backgroundColor] },
                        React.createElement("h2", { className: "s-h2 u-tc s-themecolor-1 o-lyyticalendar-box__header" }, event_1.getFormattedStartDate())),
                    React.createElement("div", { className: "u-tl" },
                        React.createElement("p", { className: "s-body-text u-mb-0" }, event_1.getFormattedTime()),
                        React.createElement("h3", { className: "s-h3 u-tl u-mt-0" }, event_1.name)))));
        }
        ;
        // Add button for revealing more events if there are still hidden events
        var showMoreButton = (!(eventsDisplayed >= this.state.events.length)) ? (React.createElement("button", { onClick: this.showMoreEvents.bind(this), className: "o-button o-lyyticalendar-button" }, this.props.translations.showMoreEventsText)) : null;
        return (React.createElement("div", null,
            React.createElement("div", { className: "row" }, content),
            React.createElement("div", { className: "row u-flex-justify--ce u-mb-12 u-mt-8" }, showMoreButton)));
    };
    return LyytiCalendarApp;
}(React.Component));
exports.default = LyytiCalendarApp;
