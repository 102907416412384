"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Subcategory = /** @class */ (function () {
    function Subcategory(json) {
        this.id = 0;
        this.name = "";
        this.heading = "";
        this.url = "/";
        this.backgroundImageUrl = "";
        this.listingImageUrl = "";
        this.ingress = "";
        this.dimFilter = false;
        this.productsText = "";
        this.containerUrlSegment = "";
        this.numberOfProducts = 0;
        this.id = json.id;
        this.name = json.name;
        this.heading = json.heading;
        this.url = json.url;
        this.backgroundImageUrl = json.backgroundImageUrl;
        this.listingImageUrl = json.listingImageUrl;
        this.ingress = json.ingress;
        this.dimFilter = json.dimFilter;
        this.productsText = json.productsText;
        this.containerUrlSegment = json.containerUrlSegment;
        this.numberOfProducts = json.numberOfProducts;
    }
    return Subcategory;
}());
exports.default = Subcategory;
