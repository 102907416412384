"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
require("jquery");
var history_1 = require("history");
var Store = /** @class */ (function () {
    function Store() {
        this.skip = 0;
        this.take = 20;
        this.loading = false;
        this.error = false;
    }
    Store.prototype.Search = function (reset, skipWindowStateUpdate) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        if (skipWindowStateUpdate === void 0) { skipWindowStateUpdate = false; }
        this.loading = true;
        this.error = false;
        this.skip = reset === true ? 0 : this.skip;
        $.ajax({
            type: "GET",
            url: "/api/SearchApi/Query",
            data: {
                "q": this.query,
                "take": this.take,
                "skip": this.skip
            },
            success: function (data) {
                _this.results = data;
                _this.previousQuery = _this.query;
                _this.loading = false;
                if (!skipWindowStateUpdate) {
                    _this.UpdateWindowState();
                }
            },
            error: function () {
                _this.loading = false;
                _this.error = true;
            }
        });
    };
    Store.prototype.ChangePage = function (page) {
        this.skip = (page - 1) * this.take;
        this.Search();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    Store.prototype.UpdateWindowState = function () {
        var object = {
            query: this.query,
            skip: this.skip
        };
        var history = history_1.createBrowserHistory();
        history.push(window.location.pathname + "?" + $.param(object), object);
        //window.history.pushState(object, null, window.location.pathname + "?" + $.param(object));
    };
    __decorate([
        mobx_1.observable
    ], Store.prototype, "query", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "previousQuery", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "results", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "skip", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "take", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "loading", void 0);
    __decorate([
        mobx_1.observable
    ], Store.prototype, "error", void 0);
    __decorate([
        mobx_1.action.bound
    ], Store.prototype, "Search", null);
    __decorate([
        mobx_1.action.bound
    ], Store.prototype, "ChangePage", null);
    __decorate([
        mobx_1.action.bound
    ], Store.prototype, "UpdateWindowState", null);
    return Store;
}());
var store = new Store();
exports.default = store;
