"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var Filters_1 = require("./Elements/Filters");
var ProductBox_1 = require("./Elements/ProductBox");
var Store_1 = require("./Store");
var ProductList = /** @class */ (function (_super) {
    __extends(ProductList, _super);
    function ProductList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductList.prototype.componentDidMount = function () {
        Store_1.default.siteName = "Hartwall";
        document.title = Store_1.default.currentPageName + " | " + Store_1.default.siteName;
        //store.updateNavigationColor(store.containerId);
    };
    ProductList.prototype.render = function () {
        var ingressText = "";
        if (Store_1.default.SelectedCategories.length > 0) {
            if (Store_1.default.SelectedCategories[0].categoryIngress && Store_1.default.SelectedCategories[0].categoryIngress.length > 0) {
                ingressText = Store_1.default.SelectedCategories[0].categoryIngress;
            }
        }
        return (React.createElement("article", { id: "main-content" },
            React.createElement("div", { className: "o-container" },
                React.createElement(Filters_1.default, { headerText: "", ingressText: ingressText }),
                Store_1.default.SelectedBrand !== null &&
                    React.createElement("div", { className: "o-brand-banner" },
                        React.createElement("div", { style: { backgroundImage: "url(" + Store_1.default.SelectedBrand.imageUrl + ")" } },
                            React.createElement("h1", { className: "u-mx-0" }, Store_1.default.SelectedBrand.name)),
                        React.createElement("h2", { className: "s-h2" }, Store_1.default.SelectedBrand.ingress)),
                React.createElement("div", { className: "o-product-listing-wrapper" },
                    Store_1.default.loading &&
                        React.createElement("div", { className: "ui active inverted dimmer" },
                            React.createElement("div", { className: "ui text loader" }, Store_1.default.Translations.loadingText)),
                    React.createElement("div", { className: "row u-pb-12" }, Store_1.default.Products && Store_1.default.Products.map(function (product, index) {
                        return (React.createElement(ProductBox_1.default, { key: product.id, product: product }));
                    }))))));
    };
    ProductList = __decorate([
        mobx_react_1.observer
    ], ProductList);
    return ProductList;
}(React.Component));
exports.default = ProductList;
