"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Cookies = require("js-cookie");
var history_1 = require("history");
var React = require("react");
var ReactDOM = require("react-dom");
var ProductsApp_1 = require("./ReactComponents/Products/ProductsApp");
var SearchApp_1 = require("./ReactComponents/Search/SearchApp");
var CreditApplicationApp_1 = require("./ReactComponents/CreditApplication/CreditApplicationApp");
var LyytiCalendarApp_1 = require("./ReactComponents/LyytiCalendar/LyytiCalendarApp");
/* This file is meant to contain javascript that is included in every site
 *
 *
 *
 *
 */
function Init() {
    // Normal window.history doesn't work correcly, webpack build crashed. I think it has to do with some npm packages require 'history' package that conflict with window.history.
    var history = history_1.createBrowserHistory();
    var whiteNavigation = true;
    window.closeSearch = function () {
        $("#page-wide-search-wrapper").removeClass("display-search-field");
        $("body").removeClass("u-ofh");
        $(".main-search-button i").html("search");
        $(".o-mobile-menu").removeClass("u-fixed");
        if (!whiteNavigation) {
            $(".blue-company-logo").show();
            $(".white-company-logo").hide();
            $(".o-navigation, .o-mobile-menu").removeClass("FFF");
        }
    };
    window.openSearch = function () {
        $("#page-wide-search-wrapper").addClass("display-search-field");
        $("body").addClass("u-ofh");
        $(".main-search-button i").html("close");
        $(".o-mobile-menu").addClass("u-fixed");
        if (!whiteNavigation) {
            $(".o-navigation, .o-mobile-menu").addClass("FFF");
            $(".blue-company-logo").hide();
            $(".white-company-logo").show();
        }
        $("#main-search-input").focus();
    };
    window.openLanguage = function () { };
    window.closeLanguage = function () { };
    window.openMobileNav = function () { };
    window.isSafari = function () {
        return (navigator.platform && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform));
    };
    // Generic function to detect if user is browsing with a touch device.
    // usage e.g. like if((<any>window).IS_TOUCH_DEVICE() === true) { }
    window.IS_TOUCH_DEVICE = function () {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function (query) {
            return window.matchMedia(query).matches;
        };
        if (('ontouchstart' in window) || window.DocumentTouch) {
            return true;
        }
        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    };
    $(document).ready(function () {
        // Add fullbleed bgcolor on parent for content-image block 
        //NOTE React components can't utilize this, so adjust the contentArea component accordingly
        $(".fullbleed .image-content-block--has-bg-color").each(function () {
            $(this).parent().css("background-color", $(this).css("background-color")).addClass("_sm-u-py-4");
        });
        // Remove parallax class from touch devices
        if (window.IS_TOUCH_DEVICE() === true) {
            $('.o-full-width-content-block .parallax').each(function () {
                $(this).removeClass('parallax');
            });
        }
        // HISTORY SECTION
        // See HTML implementation from OLD site, for example. Also see necessary css selectors from Original Long Drink SASS files.
        if ($('.o-history-section').length > 0) {
            //console.log('History');
            var strech = $('.o-history-section').attr('data-strech');
            $('.o-history-bg-image').each(function () {
                var dim = 0;
                if ($(this).attr('data-dim') != 'False') {
                    dim = -20;
                }
                if (strech == 'true') {
                    $(this).attr('style', 'background-image: url(' + $(this).attr('data-image') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) * 100 + '&height=' + Math.ceil($('.o-history-selected').height() / 100) * 100 + '&quality=65&mode=crop&brightness=' + dim + '), url(' + $(this).attr('data-image') + '?width=' + Math.ceil($('.o-history-selected').width() / 30) + '&height=' + Math.ceil($('.o-history-selected').height() / 30) + '&quality=60&mode=crop&brightness=' + dim + ')');
                }
                else {
                    $(this).attr('style', 'background-image: url(' + $(this).attr('data-image') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) * 100 + '&quality=65&brightness=' + dim + '), url(' + $(this).attr('data-image') + '?width=' + Math.ceil($('.o-history-selected').width() / 30) + '&quality=60&brightness=' + dim + ')');
                }
                $(this).attr('data-image', '');
            });
            /*
            $('.o-history-small-images img').each(function () {
                $(this).attr('src', $(this).attr('data-src') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) + '&height=' + Math.ceil($('.o-history-selected').height() / 100) + '&mode=crop&brightness=-30');
            });
            */
            $('.o-history-folders div').each(function () {
                var part1 = $(this).text().slice(0, 2);
                var part2 = $(this).text().slice(2, 4);
                //console.log(part1 + ':' + part2);
                $(this).html(part1 + '<br>' + part2);
            });
            if (window.IS_TOUCH_DEVICE() === true) {
                var toggleMiddleElementStyling = function () {
                    var allHistoryEvents = $('.o-history-event'), windowHeight = $(window).height(), targetAreaTop = windowHeight * .40, targetAreaBottom = windowHeight * .70;
                    $(window).on('scroll', function () {
                        $(allHistoryEvents).each(function () {
                            var history_event_id = $(this).attr('data-event');
                            var thisTop = $(this).offset().top - $(window).scrollTop();
                            if (thisTop >= targetAreaTop && (thisTop + $(this).height()) <= targetAreaBottom) {
                                if ($('.o-history-event.active').length < 1) {
                                    $(".o-history-bg-image").removeClass("is-active");
                                    $("." + history_event_id).addClass("is-active");
                                    $(this).addClass('active');
                                }
                            }
                            else {
                                $(this).removeClass('active');
                                $("." + history_event_id).removeClass("is-active");
                            }
                        });
                    });
                };
                $(window).on('scroll resize load', toggleMiddleElementStyling);
            }
            else {
                $('.o-history-event').hover(function () {
                    var history_event_id = $(this).attr('data-event');
                    $(".o-history-bg-image").removeClass("is-active");
                    $("." + history_event_id).addClass("is-active");
                    //console.log('hover' + $(this).attr('data-background'));
                    /*
                    if ($(this).data('dimfilter') === "True") {
                        $('.js-dimfilter').show();
                    }*/
                    //$('.o-history-selected .bg-image.bg-hires').css('background-image', 'url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width()) + '&height=' + Math.ceil($('.o-history-selected').height()) + '&mode=crop), url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) + '&height=' + Math.ceil($('.o-history-selected').height() / 100) + '&mode=crop)');
                    //$('.o-history-selected .bg-image.bg-hires').css('background-image', 'url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width()) + '&height=' + Math.ceil($('.o-history-selected').height()) + '&mode=crop)');
                    //$('.o-history-selected .bg-image.bg-lores').css('background-image', 'url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) + '&height=' + Math.ceil($('.o-history-selected').height() / 100) + '&mode=crop)');
                    $('.o-history-section').addClass('active');
                    $('.o-history-event').each(function () {
                        $(this).removeClass('active');
                    });
                    $('.o-history-folders div').each(function () {
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                    $('.o-history-folders [data-folder="' + $(this).attr('data-folder') + '"]').addClass('active');
                });
            }
            $('.o-history-gindex').hover(function () {
                $(".o-history-bg-image").removeClass("is-active");
            });
            $('.o-history-section, .o-history-event').mouseleave(function () {
                $('.o-history-event').each(function () {
                    $(this).removeClass('active');
                });
                $(this).removeClass('active');
            });
            $('.o-history-event').mouseleave(function () {
                //$('.o-history-selected .bg-image.bg-hires').css('background-image', 'none');
                //$('.o-history-selected .bg-image.bg-lores').css('background-image', 'none');
                //$('.js-dimfilter').hide();
            });
            // Add relative class on ".content-wrapper"
            $(".content-wrapper").addClass("u-relative");
            // Add absolute class on ".o-history-section" when footer comes in view
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= $('.o-history-section').offset().top + $('.o-history-section').outerHeight() - window.innerHeight) {
                    $(".o-history-selected").addClass("o-history-selected--is-absolute");
                }
                else {
                    $(".o-history-selected").removeClass("o-history-selected--is-absolute");
                }
                if ($(window).scrollTop() === 0) {
                    $(".o-history-bg-image").removeClass('is-active');
                }
            });
        }
        ;
        if ($('.o-wiki-bg').length > 0) {
            $("html").addClass("js-validate-age");
        }
        //END HISTORY SECTION
        whiteNavigation = $(".o-navigation").first().hasClass("FFF");
        // Check Video AutoPlay for safari
        var mediaElements = $('.o-video-container').children('video');
        var media = mediaElements.get(0);
        //console.log("Media is " + media);
        if (media != undefined) {
            if (media.autoplay) {
                console.log("Media autoplay is set to true");
                if (!($('.autoPlayYes').length > 0)) {
                    console.log("Media is playing itself, though autoplay set to false");
                    console.log("media.currentTime is " + media.currentTime);
                    console.log("!media.paused status is " + !media.paused);
                    console.log("!media.ended status is " + !media.ended);
                    console.log("media.readystate is " + media.readyState);
                    var isVideoPlaying = media.currentTime > 0 && !media.paused && !media.ended && media.readyState > 2;
                    if (isVideoPlaying) {
                        console.log("Making media to pause intentionally");
                        media.pause();
                    }
                }
                else {
                    console.log("Media is playing due to AutoPlay property");
                }
            }
        }
        //Check Video AutoPlay for safari
        //Article page dropdown functionality
        var dropdown = $('.js-dropdown-select');
        var dropdownItems = $('.js-dropdown-items');
        dropdown.click(function () {
            var btnItem = $(this).find('.insp-btn-item');
            $(this).toggleClass('bg-white');
            $(this).toggleClass('insp-dropdown-btn-border');
            btnItem.toggleClass('color-blue');
            dropdownItems.toggleClass('show');
            dropdownItems.toggleClass('insp-dropdown-border');
            $(this).find('.button').toggleClass('flipped');
        });
        if ($('.insp-parallax').length > 0) {
            //console.log($(window).scrollTop() + $(window).height());
            /*
            $('.insp-parallax').each(function () {
                console.log($(this).offset().top);
            });

            console.log('-----');
            */
            $(window).scroll(function () {
                $('.insp-parallax').each(function () {
                    //console.log($(this).offset());
                    var pos = ($(window).scrollTop() + $(window).height() - $(this).offset().top) / $(window).height() * 100;
                    if (pos < 0) {
                        $(this).css('padding-top', '100px');
                        $(this).css('padding-bottom', '0px');
                    }
                    else if (pos > 100) {
                        $(this).css('padding-top', '0px');
                        $(this).css('padding-bottom', '100px');
                    }
                    else {
                        $(this).css('padding-top', (100 - pos) + 'px');
                        $(this).css('padding-bottom', pos + 'px');
                    }
                    //console.log(($(window).scrollTop() + $(window).height() - $(this).offset().top) / $(window).height() * 100);
                });
            });
        }
        // Responsive video selection since html5 video media queries does not work on Chrome
        if ($('.o-hero-slide__video').length > 0) {
            if ($(window).width() <= 640 && $('.o-hero-slide__video').attr('data-src-mobile') != "") {
                $('.o-hero-slide__video').children('source').attr('src', $('.o-hero-slide__video').attr('data-src-mobile'));
            }
            else {
                $('.o-hero-slide__video').children('source').attr('src', $('.o-hero-slide__video').attr('data-src-desktop'));
            }
            //let vPlayer = $('.o-hero-slide__video')[0] as HTMLVideoElement; //Might be better
            var vPlayer = document.getElementById('hero-video');
            vPlayer.load();
        }
        ;
        if ($('.o-back-to-top').length > 0) {
            $(window).scroll(function () {
                if ($(window).scrollTop() > ($(window).height() * 1.1)) {
                    $('.o-back-to-top').removeClass('hide');
                }
                else {
                    $('.o-back-to-top').addClass('hide');
                }
            });
            $('.o-back-to-top').click(function () {
                $("html, body").animate({ scrollTop: 0 }, "slow");
            });
        }
        ;
        /*
            $('.field.active').each(function () {
                $(this).removeClass('active');
            });
            */
        $('.formfield').focus(function () {
            $(this).parent().addClass('active');
        });
        $('.formfield').focusout(function () {
            if ($(this).val().length > 0) {
                $(this).parent().addClass('filled');
            }
            else {
                $(this).parent().removeClass('filled');
            }
            $(this).parent().removeClass('active');
        });
        //happyjoe change
        $('.o-contactform-noborder .FormTextbox__Input').focus(function () {
            $(this).parent().addClass('active');
        });
        $('.o-contactform-noborder .FormTextbox__Input').focusout(function () {
            if ($(this).val().length > 0) {
                $(this).parent().addClass('filled');
            }
            else {
                $(this).parent().removeClass('filled');
            }
            $(this).parent().removeClass('active');
        });
        $('.o-contactform-noborder .FormCaptcha img').each(function () {
            $(this).next('button').addBack().wrapAll('<div class="FormCaptha_Image_Button"/>');
        });
        //happyjoe change
        function closeAgeValidation() {
            $("html").removeClass("js-validate-age");
            $(".o-age-validation, .black-filter").animate({
                top: "-100vh"
            }, 300, function () {
                $(".o-age-validation").hide();
            });
        }
        function wikipagesubmit() {
            var password = document.getElementById("passwordTextBox").value;
            var redirectUrl = document.getElementById("redirectUrl").value;
            var currentPage = $("wikipagesubmit").attr("data-password-list");
            $.ajax({
                url: 'CheckPassword',
                type: 'post',
                data: {
                    currentPage: currentPage,
                    password: password,
                    redirectUrl: redirectUrl
                },
                success: function (data) {
                    if (data == "") {
                        $(".o-wiki-error-message").html("Enter correct password");
                        $(".o-wiki-error").addClass("o-wiki-show-error");
                    }
                    else if (data == "No subpage found") {
                        $(".o-wiki-error-message").html("No sub pages found. ");
                        $(".o-wiki-error").addClass("o-wiki-show-error");
                    }
                    else {
                        window.location.href = data;
                    }
                }
            });
        }
        $("#wiki-login-form").on("submit", function (e) {
            e.preventDefault();
            wikipagesubmit();
        });
        // Clear wiki error on input change and submit the form if Enter is pressed
        $('.o-wiki-password').on('keyup', function (event) {
            $(".o-wiki-error").removeClass("o-wiki-show-error");
            if (event.keyCode === 13) {
                wikipagesubmit();
            }
        });
        $("#age-yes").click(function () {
            Cookies.set("adult", "true", { path: '/' });
            closeAgeValidation();
        });
        $("#age-no").click(function () {
            Cookies.set("adult", "false", { path: '/' });
            var redirectUrl = $("#age-no").attr("data-redirect-url");
            if (redirectUrl) {
                window.location.href = redirectUrl;
                return;
            }
            var refererhost = document.referrer.split('/')[2];
            if (document.referrer && document.referrer !== document.location.href && refererhost === document.location.host) {
                history.goBack();
            }
            else {
                window.location.href = document.location.origin;
            }
            closeAgeValidation();
        });
        $("#professional-yes").click(function () {
            Cookies.set("professional", "true", { path: '/' });
            closeAgeValidation();
        });
        $("#professional-no").click(function () {
            Cookies.set("professional", "false", { path: '/' });
            var redirectUrl = $("#professional-no").attr("data-redirect-url");
            if (redirectUrl) {
                window.location.href = redirectUrl;
                return;
            }
            var refererhost = document.referrer.split('/')[2];
            if (document.referrer && document.referrer !== document.location.href && refererhost === document.location.host) {
                history.goBack();
            }
            else {
                window.location.href = document.location.origin;
            }
            closeAgeValidation();
        });
        $("#accept-cookies").click(function () {
            Cookies.set("acceptedcookies", "true", { path: '/' });
            $(".o-cookie-notification").addClass("closed");
        });
        $("#main-search-input").on("click", function (e) {
            e.stopPropagation();
        });
        function CloseMobileNavigation() {
            $(".mobile-menu-open-trigger").removeClass("dropdown-is-active");
            $(".o-mobile-nav").removeClass("open");
            $("body").removeClass("u-ofh");
            $(".o-mobile-menu").removeClass("open");
        }
        function OpenMobileNavigation() {
            $(".mobile-menu-open-trigger").addClass("dropdown-is-active");
            $(".o-mobile-nav").addClass("open");
            $("body").addClass("u-ofh");
            $(".o-mobile-menu").addClass("open");
            window.openMobileNav();
        }
        $(".mobile-menu-open-trigger").on("click", function (e) {
            window.closeSearch();
            window.closeLanguage();
            $(".o-mobile-menu").hasClass("open") ? CloseMobileNavigation() : OpenMobileNavigation();
        });
        $("#main-search-button, #page-wide-search-wrapper").on("click", function (e) {
            e.preventDefault();
            CloseMobileNavigation();
            $("#page-wide-search-wrapper").hasClass("display-search-field") ? window.closeSearch() : window.openSearch();
        });
        $(".main-language-button").on("click", function (e) {
            e.preventDefault();
            CloseMobileNavigation();
            $("#page-wide-language-wrapper").hasClass("display-language-field") ? window.closeLanguage() : window.openLanguage();
        });
        $("#main-search-input, .execute-search").on("keydown click", function (e) {
            if (e.key === "Enter" || (e.type === "click" && $(e.target).hasClass("execute-search"))) {
                window.location.href = $(this).parent().data("search-url") + "?query=" + $("#main-search-input").val();
            }
        });
        $(".toggle-accordion").on("click", function () {
            $(this).parent(".accordion-pane-block").toggleClass("open");
        });
        $(".read-more-button").on("click", function () {
            $(this).parent().animate({ "height": "0", "opacity": 0, "margin-top": 0, "margin-bottom": 0 }, 800);
            $(".article-mainbody").first().animate({ "max-height": "100%" }, 800);
        });
        //add space after a FullWidthContentReferenceBlock if it is followed by an ImageContentBlock
        $('[data-block-type="fullwidthcontentreferenceblock"]').each(function (i, block) {
            var nextBlock = block.nextElementSibling;
            if (nextBlock) {
                if ($(nextBlock).data('block-type') === "imagecontentblock") {
                    $(block).addClass("u-mb-6");
                }
                return;
            }
            // check if the next block has "outside of container" option enabled
            var parentContainer = block.parentElement;
            if (!parentContainer)
                return;
            var nextContainer = parentContainer.nextElementSibling;
            if (nextContainer && $(nextContainer.firstChild).data('block-type') === 'imagecontentblock') {
                $(block).addClass('u-mb-6');
            }
        });
        // Video block play button
        $("body").on("click", ".play-video", function (e) {
            e.preventDefault();
            var videoId = $(this).data("video-id");
            var iframeId = $(this).data("iframe-id");
            var hideControls = $(this).data("hide-controls");
            var videoElem = document.getElementById("video-" + videoId);
            var iframeElem = document.getElementById("iframe-" + iframeId);
            if (videoElem) {
                videoElem.controls = hideControls !== "True";
                videoElem.play();
                $(this).closest(".o-video-thumbnail").hide();
            }
            else if (iframeElem) {
                $(iframeElem).show();
                $(this).closest(".o-video-thumbnail").hide();
            }
        });
        // Scroll hero 
        $("body").on("click", ".o-scroll-hero .o-round-button", function (e) {
            $("html, body").animate({ scrollTop: $(window).height() });
        });
    });
    //add underline to active link in navigation menu both for desktop and mobile
    var navItem = $('.quick-links > a');
    var mobileNavItem = $('.quicklink-item');
    var path = window.location.pathname;
    for (var i = navItem.length; i--;) {
        var item = $(navItem[i]);
        if (path == item.attr('href')) {
            item.addClass('active');
        }
    }
    for (var i = mobileNavItem.length; i--;) {
        var item = $(mobileNavItem[i]);
        if (path == item.attr('href')) {
            item.addClass('active');
        }
    }
    //resize font according to parent width
    var flexFont = function (multiplier) {
        var divs = document.getElementsByClassName("flexFont");
        for (var i = 0; i < divs.length; i++) {
            var relFontsize = divs[i].offsetWidth * multiplier;
            divs[i].style.fontSize = relFontsize + 'px';
        }
    };
    window.onload = function () {
        flexFont(0.3);
    };
    window.onresize = function () {
        flexFont(0.3);
    };
    window.addFullWidth = function () {
    };
    window.removeFullWidth = function () {
    };
}
exports.Init = Init;
//---------------------------------------------------------------------------------------
function ImportCoreweb() {
    // We load every .js file under /UI/js/Coreweb so other packages can insert their code there and it will be automatically included
    function requireAll(r) { r.keys().forEach(r); }
    requireAll(require.context('./Coreweb/', true, /\.js$/));
}
exports.ImportCoreweb = ImportCoreweb;
exports.DefaultComponentTypes = {
    "ProductsApp": ProductsApp_1.default,
    "SearchApp": SearchApp_1.default,
    "CreditApplication": CreditApplicationApp_1.default,
    "LyytiCalendarApp": LyytiCalendarApp_1.default
};
// Insert custom components as parameters and leave empty to use defaults
function InitReact(components) {
    if (components === void 0) { components = exports.DefaultComponentTypes; }
    document.addEventListener('DOMContentLoaded', function () {
        function initialize(componentContainer) {
            var props = JSON.parse(componentContainer.dataset.props || '{}');
            props.propertyName = componentContainer.dataset.epiPropertyName;
            ReactDOM.render(React.createElement(components[componentContainer.dataset.reactComponent], props), componentContainer);
        }
        document.querySelectorAll('[data-react-component]').forEach(initialize);
    });
}
exports.InitReact = InitReact;
