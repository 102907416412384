"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function InitBooster() {
    var isWhiteNav = false;
    window.closeLanguage = function () {
        $("#page-wide-language-wrapper").removeClass("display-language-field");
        $("body").removeClass("u-ofh");
        $(".main-language-button i").html("language");
        $(".o-mobile-menu").removeClass("u-fixed");
        if (!isWhiteNav) {
            $('.o-navigation, .o-mobile-menu').removeClass('FFF');
        }
    };
    window.openLanguage = function () {
        $("#page-wide-language-wrapper").addClass("display-language-field");
        $("body").addClass("u-ofh");
        $(".main-language-button i").html("close");
        $(".o-mobile-menu").addClass("u-fixed");
        $('.o-navigation, .o-mobile-menu').addClass('FFF');
    };
    window.openMobileNav = function () {
        $(".o-navigation, .o-mobile-menu").addClass("FFF");
    };
    $(document).ready(function () {
        isWhiteNav = $('.o-navigation').hasClass('FFF');
        $("body").on("click", ".o-scroll-hero .o-round-button", function (e) {
            $("html, body").animate({ scrollTop: $(window).height() });
        });
        $("body").on("click", ".play-video", function (e) {
            e.preventDefault();
            var videoId = $(this).data("video-id");
            var iframeId = $(this).data("iframe-id");
            var hideControls = $(this).data("hide-controls");
            var videoElem = document.getElementById("video-" + videoId);
            var iframeElem = document.getElementById("iframe-" + iframeId);
            if (videoElem) {
                videoElem.controls = hideControls !== "True";
                videoElem.play();
                $(this).closest(".o-video-thumbnail").hide();
            }
            else if (iframeElem) {
                $(iframeElem).show();
                $(this).closest(".o-video-thumbnail").hide();
            }
        });
        $('div [data-block-type="imagecontentblock"] .s-mobile-link').each(function () {
            $(this).addClass('c-cta');
            $(this).text($(this).text().replace('→', ''));
        });
        $('.slick-dots.slick-arrow .s-circle.arrow-circle, .o-back-to-top .s-circle.arrow-circle').empty();
        $('.slick-dots.slick-arrow .s-circle.arrow-circle').html('<i class="material-icons">arrow_downward</i>');
        $('.o-back-to-top .s-circle.arrow-circle').html('<i class="material-icons">arrow_upward</i>');
        if (window.isSafari()) {
            $('.s-product-header').each(function () {
                $(this).addClass('safari');
            });
        }
    });
}
exports.InitBooster = InitBooster;
