"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LyytiEvent = /** @class */ (function () {
    function LyytiEvent(json) {
        this.id = "";
        this.end_time_utc = 0;
        this.start_time_utc = 0;
        this.name = "";
        this.enrollment_url = "";
        this.startDate = new Date();
        this.endDate = new Date();
        /**
         * Adds leading zero to the given number if it's single digit. 5 => "05"
         */
        this.formatTwoDigits = function (n) { return n < 10 ? '0' + n : n; };
        this.id = json.id;
        this.end_time_utc = json.end_time_utc;
        this.start_time_utc = json.start_time_utc;
        if (json.name) {
            if (json.name.fi)
                this.name = json.name.fi;
            else if (json.name.en)
                this.name = json.name.en;
        }
        this.enrollment_url = json.enrollment_url;
        this.startDate = new Date(this.start_time_utc * 1000);
        this.endDate = new Date(this.end_time_utc * 1000);
    }
    /**
     * Returns event's start date as a formatted string. For example: "29.11."
     */
    LyytiEvent.prototype.getFormattedStartDate = function () {
        return this.startDate.getDate() + "." + (this.startDate.getMonth() + 1) + ".";
    };
    /**
     * Returns string describing the month and year of the event's start date. For example: "Kesäkuu 2019"
     */
    LyytiEvent.prototype.getHeaderForMonth = function () {
        var month = this.startDate.toLocaleString('fi', { month: 'long' }); //returns the full name of the month
        return month.charAt(0).toUpperCase() + month.slice(1) + " " + this.startDate.getFullYear();
    };
    /**
     * Returns string depicting event's duration. For example: "13:00-15:30"
     */
    LyytiEvent.prototype.getFormattedTime = function () {
        return this.formatTwoDigits(this.startDate.getHours()) + "." + this.formatTwoDigits(this.startDate.getMinutes()) + "-" + this.formatTwoDigits(this.endDate.getHours()) + "." + this.formatTwoDigits(this.endDate.getMinutes());
    };
    return LyytiEvent;
}());
exports.default = LyytiEvent;
