"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('select2');
require('./../scss/sites/booster/booster.scss');
var index_1 = require("./../js/index");
var booster_1 = require("./Sites/booster");
// Initialize scripts that are used in all websites
index_1.Init();
// Initialize Booster specific scripts
booster_1.InitBooster();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
$(document).ready(function () {
    //Initialize select2 for Booster Contact form
    $('.Form__Element.FormSelection select').hide();
    $('.Form__Element.FormSelection select').select2({
        minimumResultsForSearch: Infinity,
        width: 'resolve',
        dropdownParent: $('.Form__MainBody')
    });
});
