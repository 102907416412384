"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Brand = /** @class */ (function () {
    function Brand(json) {
        this.id = 0;
        this.name = "";
        this.parentCategory = 0;
        this.imageUrl = null;
        this.listingImageUrl = null;
        this.url = "/";
        this.ingress = "";
        this.k18 = false;
        this.dimFilter = false;
        this.numberOfProducts = 0;
        this.hasShortcutUrl = false;
        this.id = json.id;
        this.url = json.url;
        this.name = json.name;
        this.parentCategory = json.parentCategory;
        this.imageUrl = json.imageUrl;
        this.listingImageUrl = json.listingImageUrl;
        this.ingress = json.ingress;
        this.k18 = json.k18;
        this.dimFilter = json.dimFilter;
        this.numberOfProducts = json.numberOfProducts;
        this.hasShortcutUrl = json.hasShortcutUrl;
    }
    return Brand;
}());
exports.default = Brand;
