"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Store_1 = require("./Store");
var SearchInput_1 = require("./Elements/SearchInput");
var SearchResultsInfo_1 = require("./Elements/SearchResultsInfo");
var mobx_react_1 = require("mobx-react");
var SearchPagination_1 = require("./Elements/SearchPagination");
var history_1 = require("history");
var SearchApp = /** @class */ (function (_super) {
    __extends(SearchApp, _super);
    function SearchApp(props) {
        var _this = _super.call(this, props) || this;
        Store_1.default.query = props.query;
        Store_1.default.previousQuery = props.query;
        Store_1.default.results = props.preloadedResults;
        Store_1.default.skip = props.skip;
        Store_1.default.take = props.take;
        Store_1.default.translations = props.translations;
        var history = history_1.createBrowserHistory();
        history.listen(function (location) {
            Store_1.default.skip = location.state.skip;
            Store_1.default.query = location.state.query;
            Store_1.default.Search(false, true);
        });
        return _this;
    }
    SearchApp.prototype.render = function () {
        return React.createElement("article", { id: "main-content" },
            React.createElement("div", { className: "o-container" },
                React.createElement("div", { className: "row col-sx-12 off-md-1 col-md-10" },
                    React.createElement("div", { id: "search-results", className: "o-container" },
                        React.createElement(SearchInput_1.default, null),
                        React.createElement("div", null,
                            React.createElement("h2", { className: "s-h2" }, Store_1.default.previousQuery && Store_1.default.translations.searchResultsTitle)),
                        Store_1.default.previousQuery && React.createElement(SearchResultsInfo_1.default, null),
                        Store_1.default.results && React.createElement(SearchPagination_1.default, null),
                        React.createElement("div", { className: "results" },
                            Store_1.default.loading &&
                                React.createElement("div", { className: "ui active inverted dimmer" },
                                    React.createElement("div", { className: "ui text loader" }, Store_1.default.translations.loading)),
                            Store_1.default.results && Store_1.default.results.hits.map(function (item, index) {
                                return (React.createElement("div", { key: index, className: "listResult" },
                                    React.createElement("h3", null,
                                        React.createElement("a", { className: "s-h3", href: item.url }, item.title)),
                                    item.hasHighlights === true ? React.createElement("p", { dangerouslySetInnerHTML: { __html: item.excerpt } }) : React.createElement("p", null, item.summary)));
                            })),
                        Store_1.default.results && React.createElement(SearchPagination_1.default, null)))));
    };
    SearchApp = __decorate([
        mobx_react_1.observer
    ], SearchApp);
    return SearchApp;
}(React.Component));
exports.default = SearchApp;
