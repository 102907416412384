"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("jquery");
var ContentArea = /** @class */ (function (_super) {
    __extends(ContentArea, _super);
    function ContentArea(props) {
        var _this = _super.call(this, props) || this;
        _this._isMounted = false;
        _this._loadedBackupContentArea = false;
        _this.state = {
            contentarea: null,
            content: props.content,
            area: props.area,
            loading: false,
            language: props.language
        };
        return _this;
    }
    ContentArea.prototype.imagescaling = function (data) {
        var hData = $(data);
        var bgs = hData.find('*[data-scaling-bg]');
        bgs.each(function (i) {
            var parentscaling = $(this).attr('data-parent-scaling');
            if (parentscaling != undefined && parentscaling) {
                var src = $(this).attr('data-scaling-bg') + '?width=' + $(this).parent().width();
            }
            else {
                //TODO Fix the image scaling?
                var src = $(this).attr('data-scaling-bg') + '?width=' + Math.ceil($(this).width());
            }
            $(this).css('background-image', 'url(' + src + ')');
            $(this).removeAttr("data-scaling-bg");
        });
        //Add the "row" class to the outer div so that the block inside the content area are displayed correctly
        hData.addClass('row');
        //Apply styles of the inner div for background color. Equivalent to the script in index.ts
        hData.find('.block').each(function () {
            if ($(this).parent().hasClass('fullbleed')) {
                var style = $(this).attr('style') || "";
                style += $(this).children(':first').attr('style') || "";
                $(this).attr('style', style);
                if ($(this).find('.image-content-block--has-bg-color').length > 0)
                    $(this).addClass('_sm-u-py-4');
            }
        });
        return $('<div>').append(hData).clone().html();
    };
    ContentArea.prototype.componentDidMount = function () {
        this._isMounted = true;
        this.LoadContentArea();
    };
    ContentArea.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    ContentArea.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.content !== prevProps.content) {
            this._loadedBackupContentArea = false;
            this.LoadContentArea(this.props.content);
        }
        var body = $('body');
        var dangerous = body.find('script.dangerous_react_rendered');
        var clones = dangerous.clone();
        clones.removeClass('dangerous_react_rendered').addClass('came_from_react_render');
        dangerous.remove();
        body.append(clones.prop('outerHTML'));
    };
    ContentArea.prototype.LoadContentArea = function (content, area) {
        var _this = this;
        var imageScaling = this.imagescaling;
        this.setState({
            loading: true
        }, function () { return $.ajax({
            type: "GET",
            url: "/Render/RenderContentArea",
            data: {
                "content": content || _this.state.content,
                "area": area || _this.state.area,
                "language": _this.state.language
            },
            success: function (data) {
                if (this._isMounted) {
                    if (!data && this.props.backupArea && this.props.backupContent && this._loadedBackupContentArea === false) {
                        this._loadedBackupContentArea = true;
                        this.LoadContentArea(this.props.backupContent, this.props.backupArea);
                    }
                    else {
                        this.setState({ contentarea: imageScaling(data), loading: false });
                    }
                }
            }.bind(_this),
            error: function () {
            }.bind(_this)
        }); });
    };
    ContentArea.prototype.render = function () {
        //in case there are scripts in the content area blocks
        var contents = $(this.state.contentarea);
        var scripts = contents.find('script');
        scripts.addClass('dangerous_react_rendered');
        var newContentArea = contents.prop('outerHTML');
        return (React.createElement("div", { dangerouslySetInnerHTML: { __html: newContentArea } }));
    };
    return ContentArea;
}(React.Component));
exports.default = ContentArea;
