"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Store_1 = require("../Store");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var SearchInput = /** @class */ (function (_super) {
    __extends(SearchInput, _super);
    function SearchInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchInput.prototype.onChange = function (e) {
        Store_1.default.query = e.target.value;
    };
    SearchInput.prototype.onKeyPress = function (e) {
        if (e.key === 'Enter') {
            Store_1.default.Search(true);
        }
    };
    SearchInput.prototype.render = function () {
        return (React.createElement("div", { className: "search-panel-div" },
            React.createElement("input", { id: "search-field", className: "s-h3", type: "text", value: Store_1.default.query, onChange: this.onChange, onKeyPress: this.onKeyPress }),
            React.createElement("div", { className: "c-button-common", onClick: function () { return Store_1.default.Search(true); } }, Store_1.default.translations.searchButton)));
    };
    __decorate([
        mobx_1.action.bound
    ], SearchInput.prototype, "onChange", null);
    __decorate([
        mobx_1.action.bound
    ], SearchInput.prototype, "onKeyPress", null);
    SearchInput = __decorate([
        mobx_react_1.observer
    ], SearchInput);
    return SearchInput;
}(React.Component));
exports.default = SearchInput;
