"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
require("jquery");
var Store_1 = require("../Store");
var Product = /** @class */ (function () {
    function Product(json) {
        this.id = 0;
        this.name = "";
        this.imageUrl = "";
        this.category = "";
        this.url = "";
        this.categoryId = 0;
        this.brandId = 0;
        this.bottlesize = "small";
        this.k18 = false;
        this.imageBackgroundColor = "";
        this.description = "";
        this.details = null;
        this.id = json.id;
        this.name = json.name;
        this.imageUrl = json.imageUrl;
        this.category = json.category;
        this.url = json.url;
        this.categoryId = json.categoryId;
        this.brandId = json.brandId;
        this.bottlesize = json.bottlesize;
        this.k18 = json.k18;
        this.imageBackgroundColor = json.imageBackgroundColor;
        this.description = json.description;
    }
    Product.prototype.loadDetails = function () {
        Store_1.default.loading = true;
        $.ajax({
            type: "GET",
            url: "/api/ProductApi/GetProductDetails",
            data: {
                "product": this.id,
                "language": Store_1.default.currentLanguage
            },
            success: function (data) {
                document.title = data.Name + " | " + Store_1.default.siteName;
                Store_1.default.loading = false;
                this.details = data;
            }.bind(this),
            error: function () {
            }.bind(this)
        });
    };
    __decorate([
        mobx_1.observable
    ], Product.prototype, "details", void 0);
    return Product;
}());
exports.default = Product;
