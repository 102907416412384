/// <reference path="../sites/mattsson.ts" />
import 'jquery';
import 'slick-carousel';

window.setSlide = function (itemIdx) {
    $('.Coreweb-js-slider').slick('slickGoTo', itemIdx);
}

window.setSlide = function (itemIdx, parent) {
    $(parent).find('.Coreweb-js-slider').slick('slickGoTo', itemIdx);
}

$(document).ready(function () {
    $('.Coreweb-js-slider').each(function () {

        // Add focus-style css class to current slide after change in slider. focus-style class can be used to add css transitions applied after item change. 
        $(this).on('afterChange', function (event, slick, currentSlide, nextSlide) {
            jQuery(this).find('.slick-slide').removeClass('focus-style');
            jQuery(this).find('.slick-slide.slick-current').addClass('focus-style');
            if (jQuery(this).find('.slick-slide.slick-current').find('.theme').hasClass('black')) {
                //console.log('found');
                jQuery('body.ome').addClass('themed');
                jQuery('.slick-arrow').addClass('themed');
            } else {
                //console.log('removed');
                jQuery('body.ome').removeClass('themed');
                jQuery('.slick-arrow').removeClass('themed');
            }
        });

        var autoplayspeed = $(this).attr('slick-autoplayspeed');
        var autoplay = autoplayspeed > 0;
        var isHeroSlick = $(this).hasClass("o-front-page-slick");

        if (isHeroSlick) {
            var _ = $(this);
            $(this).on("init", function (slick) {
                //console.log(_);
                _.find('.slick-current').addClass('activated');

                _.find(".slick-dots").wrap('<div class="js-slick-control" style="position: relative;bottom: 100px;margin-left: auto;margin-right: auto;max-width: 1360px;"></div>');

                $('.js-slick-control').append('<div class="slick-dots slick-arrow"><div class="s-circle arrow-circle">&darr;</div></div>');
                _.find(".slick-dots.slick-arrow").click(function (e) {
                    $("html, body").animate({ scrollTop: $(window).height() });
                });
            });
            $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                $('.indicator').css({ 'transform': 'translate3d(' + nextSlide * 100 + '%, 0, 0)' });

                //$("[data-slick-index='" + nextSlide + "'] .o-hero-slide").addClass('js-zoom');
                $("[data-slick-index='" + nextSlide + "']").addClass('activated');

                //_.find('.slick-current').addClass('activated');
            });
        }
        if ($(this).hasClass('o-front-page-slick')) {
            $(this).slick({
                autoplay: autoplay,
                autoplaySpeed: (autoplayspeed * 1000),
                dots: isHeroSlick,
                arrows: !isHeroSlick,
                slidesToShow: isHeroSlick ? 1 : 3,
                draggable: true,
                fade: true,
                cssEase: 'linear',
                customPaging: function (slider, i) {
                    //$('.slick-dots').wrap('<div style="position: relative;bottom: 100px;border: 1px dotted red;margin-left: auto;margin-right: auto;max-width: 1360px;"></div>')


                    var link = $("<div class='custom-slick-dots'><div class='s-circle slick-numbers'>" + (i + 1) + "</div><div class='slick-text'></div></div>");
                    link.find(".slick-text").text(slider.$slides.eq(i).find("h1").text());
                    return link;
                }
            });
        }
        else if ($(this).hasClass('o-filter-slider')) {
            if (window.innerWidth > 768) return true;

            let responsiveParameter = [
                {
                    breakpoint: 1200,
                    settings: "unslick"
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ];
            let body = $('body');
            if (body.hasClass('pommac')) {
                console.log('has pommac');
                responsiveParameter = [
                    {
                        breakpoint: 1200,
                        settings: "unslick"
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ];
            }

            $(this).slick({
                autoplay: false,
                autoplaySpeed: 0,
                dots: false,
                arrows: false,
                draggable: true,
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 6,
                responsive: responsiveParameter
            });
        }
        else {

            var numberOfSlides = ($(this).attr("data-numberofslides") !== undefined && $(this).attr("data-numberofslides").length > 0 && $(this).attr("data-numberofslides") > 0) ? $(this).attr("data-numberofslides") : 3;
            let dataSlide = $(this).attr("data-swipetoslide");
            var allowSwipeToSlide = !!dataSlide;
            let totalSlides = ($(this).attr("data-slidecount") !== undefined && $(this).attr("data-slidecount").length > 0 && $(this).attr("data-slidecount") > 0) ? $(this).attr("data-slidecount") : 0; 

            let responsiveParameter = [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: (numberOfSlides > 3) ? 4 : 3
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ];

            let body = $('body');
            if (body.hasClass('pommac')) {
                console.log('has pommac');
                responsiveParameter = [
                    {
                        breakpoint: 1500,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ];
            }

            var centered = false;
            var centeredPadding = '0px';
            var focused = false;

            if (body.hasClass('bryggeri')) {
                //console.log('has bryggeri');

                var manufacturing = $(this).parents('.manufacturing').length;

                numberOfSlides = (manufacturing) ? 1 : 5;
                //console.log('numberOfSlides', numberOfSlides);
                responsiveParameter = manufacturing ?
                    [
                        {
                            breakpoint: 1500,
                            settings: {
                                slidesToShow: 1,
                                centerPadding: '0px'
                            }
                        },
                        {
                            breakpoint: 750,
                            settings: {
                                slidesToShow: 1,
                                centerPadding: '0px'
                            }
                        },
                    ]
                    :
                    [
                    {
                        breakpoint: 1500,
                        settings: {
                            slidesToShow: (totalSlides > 5) ? 5 : totalSlides - 1, // Slider breaks if total number of slides is equal or smaller than slidesToShow value. 
                            centerPadding: '0px'
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: (totalSlides > 5) ? 5 : totalSlides - 1,
                            centerPadding: '0px'
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: (totalSlides > 3) ? 3 : totalSlides - 1,
                            centerPadding: '0px'
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: (totalSlides > 3) ? 3 : totalSlides - 1,
                            centerPadding: centeredPadding
                        }
                    }
                ];
                centered = manufacturing ? false : true;
                //centered = true;

                if (manufacturing) {
                    centeredPadding = '100px';
                }
                
                focused = true;
                isHeroSlick = manufacturing ? false : true;
            }

            if (body.hasClass('ome')) {
                console.log('has ome');

                let oneSlide = ($(this).parents('.products-slider').length || $(this).parents('.team-slider').length) ? true : false;

                centered = false;

                if (!oneSlide) {
                    centeredPadding = '0px';
                    //centered = true;
                    centered = false;
                }

                console.log('oneSlide', oneSlide);

                numberOfSlides = (oneSlide) ? 1 : 4;

                let numberOfDesktopSlides = (oneSlide) ? 1 : 4;

                responsiveParameter =
                    [
                        {
                            breakpoint: 1500,
                            settings: {
                                slidesToShow: numberOfDesktopSlides, // Slider breaks if total number of slides is equal or smaller than slidesToShow value. 
                                centerPadding: '0px'
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: (oneSlide) ? 1 : 2,
                                centerPadding: '0px'
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                centerPadding: '0px'
                            }
                        }
                    ];
                    
                
                focused = false;
                isHeroSlick = false;
                allowSwipeToSlide = true;
            }

            // Template A, image slider on Product details view.
            if (body.hasClass('template-a') && body.hasClass('ProductListingBlockPage')) {

                console.log('template-a ProductListingBlockPage');

                responsiveParameter = [
                    {
                        breakpoint: 1500,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ];

                numberOfSlides = 1;

                focused = false;
                isHeroSlick = false;
                allowSwipeToSlide = true;
                centered = false;

            }


            $(this).slick({
                autoplay: autoplay,
                autoplaySpeed: (autoplayspeed * 1000),
                dots: isHeroSlick,
                arrows: !isHeroSlick,
                slidesToShow: numberOfSlides,
                swipeToSlide: allowSwipeToSlide,
                draggable: true,
                customPaging: function (slider, i) {
                    var link = $("<div class='custom-slick-dots'><div class='s-circle slick-numbers'>" + (i + 1) + "</div><div class='slick-text'></div></div>");
                    link.find(".slick-text").text(slider.$slides.eq(i).find("h1").text());
                    return link;
                },
                responsive: responsiveParameter,
                centerMode: centered,
                centerPadding: centeredPadding,
                focusOnSelect: focused
                
            });
        }
    });
    if ($('.Coreweb-js-slider').length > 0) {
        $(".js-slick-full .slick-slide").css("max-height", "calc(100vh - " + $('.Coreweb-js-slider').offset().top + "px )");
        //$("[data-slick-index='0'] .o-hero-slide").addClass('js-zoom');

//        $(".js-slick-full .slick-dots").wrap('<div style="position: relative;bottom: 100px;border: 1px dotted red;margin-left: auto;margin-right: auto;max-width: 1360px;"></div>');
    }
    if ($('ul.slick-dots').length > 0) {
        let slideNumber = $('ul.slick-dots').children().length;
        $('ul.slick-dots').append('<span class="indicator"></span>');
        $('.indicator').css({ width: (100 / slideNumber) + "%" });
    }

    // If there is only one hero slide, the scroll-down button is not appended by default. Append it here.
    if (!$('ul.slick-dots').length && $('.o-hero-slide').length) {
        let scrollDownButton = $(`
                <div class="o-scroll-hero o-container row u-relative">
                    <span class="s-circle arrow-circle">
                        <i class="material-icons">arrow_downward</i>
                    </span>
                </div>`
        );

        scrollDownButton.click(function (e) {
            $("html, body").animate({ scrollTop: $(window).height() });
        });

        $('.o-slider-scroll-frontpage').append(scrollDownButton);
    }

    //Unslick
    $('.Coreweb-unslick').each(function () {
        if ($('.slick-initialized').length) {
            $(this).click(function () {
                $('.slick-initialized').slick('unslick');
                $(this).hide();
            });
        } else {
            $(this).hide();
        }
    });

});
