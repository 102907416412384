"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var react_router_dom_1 = require("react-router-dom");
var ContentArea_1 = require("./Elements/ContentArea");
var ProductRecommendations_1 = require("./Elements/ProductRecommendations");
var ProductImages_1 = require("./Elements/ProductImages");
var Store_1 = require("./Store");
var react_collapsible_1 = require("react-collapsible");
var ProductDisplay = /** @class */ (function (_super) {
    __extends(ProductDisplay, _super);
    function ProductDisplay(props) {
        var _this = _super.call(this, props) || this;
        Store_1.default.updateNavigationColor(Store_1.default.SelectedProduct ? Store_1.default.SelectedProduct.id : Store_1.default.containerId);
        return _this;
    }
    ProductDisplay.prototype.componentDidMount = function () {
        if (Store_1.default.SelectedProduct.details === null) {
            Store_1.default.SelectedProduct.loadDetails();
        }
    };
    ProductDisplay.prototype.onListingPageClick = function () {
        Store_1.default.SelectedBrand = null;
        Store_1.default.SelectedCategories.clear();
        Store_1.default.SelectedProduct = null;
        Store_1.default.loadBrands();
        Store_1.default.SelectedSubcategory = null;
    };
    ProductDisplay.prototype.onCategoryPageClick = function () {
        Store_1.default.SelectedBrand = null;
        Store_1.default.SelectedCategories.replace([Store_1.default.Categories.find(function (x) { return x.id === Store_1.default.SelectedProduct.details.Category.Id; })]);
        Store_1.default.SelectedProduct = null;
        Store_1.default.loadProducts();
        Store_1.default.SelectedSubcategory = null;
    };
    ProductDisplay.prototype.onBrandPageClick = function () {
        if (Store_1.default.SelectedProduct.details.Brand.HasShortcutUrl) {
            Store_1.default.SelectedBrand = Store_1.default.Brands.find(function (x) { return x.id === Store_1.default.SelectedProduct.details.Brand.Id; });
            //store.SelectedCategories.replace([]);
            Store_1.default.SelectedProduct = null;
            //store.SelectedSubcategory = null;
            window.location.href = Store_1.default.SelectedProduct.details.Brand.Url;
        }
        else {
            Store_1.default.SelectedBrand = Store_1.default.Brands.find(function (x) { return x.id === Store_1.default.SelectedProduct.details.Brand.Id; });
            Store_1.default.SelectedCategories.replace([]);
            Store_1.default.SelectedProduct = null;
            Store_1.default.loadProducts();
            Store_1.default.SelectedSubcategory = null;
        }
    };
    ProductDisplay.prototype.render = function () {
        window.removeFullWidth(true);
        var productDocuments = Store_1.default.SelectedProduct.details ? Store_1.default.SelectedProduct.details.Documents.map(function (doc, i) {
            return (React.createElement("div", { key: "product-document-" + i, className: "o-product-document u-mt-2" },
                React.createElement("a", { href: doc.Url, target: "_blank" },
                    React.createElement("i", { className: "material-icons" }, "insert_drive_file"),
                    React.createElement("span", null, doc.Title),
                    React.createElement("br", null),
                    React.createElement("p", { className: "u-m-0 u-inline" }, doc.Type))));
        }) : [];
        return (React.createElement("div", null,
            React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedProduct.id, language: Store_1.default.currentLanguage, area: "CampaignContentArea" }),
            React.createElement("article", { id: "main-content" },
                React.createElement("div", { className: "o-container" },
                    Store_1.default.loading &&
                        React.createElement("div", { className: "ui active inverted dimmer" },
                            React.createElement("div", { className: "ui text loader" }, Store_1.default.Translations.loadingText)),
                    Store_1.default.SelectedProduct.details &&
                        React.createElement("div", { className: "o-product-display " }, Object.keys(Store_1.default.SelectedProduct.details).length !== 0 &&
                            React.createElement("div", { className: "o-product-display-information row u-mb-4" },
                                React.createElement("div", { className: "o-mobile-breadcrumb-container m-ptb-2 u-dn d-block" },
                                    React.createElement("ul", { className: "breadcrumb" },
                                        React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedProduct.details.ListingPage.Url, href: Store_1.default.SelectedProduct.details.ListingPage.Url, onClick: this.onListingPageClick }, Store_1.default.SelectedProduct.details.ListingPage.Name)),
                                        !Store_1.default.disableCategories && React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedProduct.details.Category.Url, href: Store_1.default.SelectedProduct.details.Category.Url, onClick: this.onCategoryPageClick }, Store_1.default.SelectedProduct.details.Category.Name)),
                                        !Store_1.default.disableBrands && React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedProduct.details.Brand.Url, href: Store_1.default.SelectedProduct.details.Brand.Url, onClick: this.onBrandPageClick }, Store_1.default.SelectedProduct.details.Brand.Name)),
                                        React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement("a", { href: "javascript:void(0)" }, Store_1.default.SelectedProduct.details.Name))),
                                    React.createElement("h1", { className: "s-product-header u-my-1 m-mt-2" }, Store_1.default.SelectedProduct.details.Name)),
                                React.createElement(ProductImages_1.default, { urls: Store_1.default.SelectedProduct.details.Image, backgroundColor: Store_1.default.SelectedProduct.details.ImageBackgroundColor, size: Store_1.default.SelectedProduct.details.BottleSize }),
                                React.createElement("div", { className: "o-product-display-description col-md-5  col-xs-12 u-p-1" },
                                    React.createElement("ul", { className: "breadcrumb d-none" },
                                        React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedProduct.details.ListingPage.Url, href: Store_1.default.SelectedProduct.details.ListingPage.Url, onClick: this.onListingPageClick }, Store_1.default.SelectedProduct.details.ListingPage.Name)),
                                        !Store_1.default.disableCategories && React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedProduct.details.Category.Url, href: Store_1.default.SelectedProduct.details.Category.Url, onClick: this.onCategoryPageClick }, Store_1.default.SelectedProduct.details.Category.Name)),
                                        !Store_1.default.disableBrands && React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedProduct.details.Brand.Url, href: Store_1.default.SelectedProduct.details.Brand.Url, onClick: this.onBrandPageClick }, Store_1.default.SelectedProduct.details.Brand.Name)),
                                        React.createElement("li", { className: "o-breadcrumb-link" },
                                            React.createElement("a", { href: "javascript:void(0)" }, Store_1.default.SelectedProduct.details.Name))),
                                    React.createElement("h1", { className: "s-product-header u-my-1 d-none" }, Store_1.default.SelectedProduct.details.Name),
                                    React.createElement("div", { dangerouslySetInnerHTML: { __html: Store_1.default.SelectedProduct.details.Ingress } }),
                                    Store_1.default.SelectedProduct.details.Text &&
                                        React.createElement(react_collapsible_1.default, { transitionTime: 100, trigger: Store_1.default.Translations.productInformationHeader },
                                            React.createElement("div", { dangerouslySetInnerHTML: { __html: Store_1.default.SelectedProduct.details.Text } })),
                                    productDocuments.length > 0 &&
                                        React.createElement(react_collapsible_1.default, { transitionTime: 100, trigger: "Tuotedokumentit" }, productDocuments),
                                    Store_1.default.SelectedProduct.details.AlkoLink &&
                                        React.createElement("a", { className: "o-alko-link-container u-mt-4", target: "_blank", href: Store_1.default.SelectedProduct.details.AlkoLink },
                                            React.createElement("span", { className: "alko-link-text" }, Store_1.default.SelectedProduct.details.AlkoLinkText || Store_1.default.Translations.alkoLinkText),
                                            React.createElement("img", { src: Store_1.default.SelectedProduct.details.AlkoLinkImage, className: "o-alko-link-image", alt: "alko link" })),
                                    Store_1.default.SelectedProduct.details.AdditionalInformation &&
                                        React.createElement("div", { className: "o-additional-product-info u-mt-1", dangerouslySetInnerHTML: { __html: Store_1.default.SelectedProduct.details.AdditionalInformation } }),
                                    Store_1.default.SelectedProduct.details.StoreLink &&
                                        React.createElement("div", { className: "" },
                                            React.createElement("a", { className: "u-ib u-mt-3 u-tc o-button o-store-link-button", target: "_blank", href: Store_1.default.SelectedProduct.details.StoreLink }, Store_1.default.SelectedProduct.details.StoreLinkText || Store_1.default.Translations.storeLinkText))))))),
            React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedProduct.id, language: Store_1.default.currentLanguage, area: "ContentAreaMiddle" }),
            React.createElement("section", { id: "related-articles" },
                React.createElement("div", { className: "o-container" },
                    React.createElement(ProductRecommendations_1.default, { productId: Store_1.default.SelectedProduct.id }))),
            React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedProduct.id, language: Store_1.default.currentLanguage, area: "ProductContentArea", backupArea: "ProductContentArea", backupContent: Store_1.default.containerId })));
    };
    __decorate([
        mobx_1.action.bound
    ], ProductDisplay.prototype, "onListingPageClick", null);
    __decorate([
        mobx_1.action.bound
    ], ProductDisplay.prototype, "onCategoryPageClick", null);
    __decorate([
        mobx_1.action.bound
    ], ProductDisplay.prototype, "onBrandPageClick", null);
    ProductDisplay = __decorate([
        mobx_react_1.observer
    ], ProductDisplay);
    return ProductDisplay;
}(React.Component));
exports.default = ProductDisplay;
