"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_slick_1 = require("react-slick");
var mobx_react_1 = require("mobx-react");
var ProductImages = /** @class */ (function (_super) {
    __extends(ProductImages, _super);
    function ProductImages() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductImages.prototype.render = function () {
        var backgroundColor = this.props.backgroundColor;
        var size = "size-" + this.props.size;
        var images = null;
        if (this.props.urls && this.props.urls.length == 1) {
            var bgStyle = backgroundColor ? { backgroundColor: backgroundColor } : {};
            images = React.createElement("div", null,
                React.createElement("div", { className: "image-bg m-mt-0 " + size, style: bgStyle },
                    React.createElement("div", { className: "image" },
                        React.createElement("img", { src: this.props.urls[0] + '?height=1000' }))));
        }
        if (this.props.urls && this.props.urls.length > 1) {
            images = React.createElement("div", null,
                backgroundColor && React.createElement("style", { dangerouslySetInnerHTML: {
                        __html: ".image-bg {\n                            background-color: " + backgroundColor + "!important;\n                         }"
                    } }),
                React.createElement(react_slick_1.default, { dots: true, slidesToShow: 1, autoplay: false, arrows: true, fade: true }, this.props.urls.map(function (url, index) {
                    var bgStyle = {};
                    bgStyle.background = "url('" + url + "?height=1000') center center no-repeat";
                    bgStyle.backgroundSize = "contain";
                    return (React.createElement("div", { key: index, className: "image-bg m-mt-0 " + size },
                        React.createElement("div", { className: "image", style: bgStyle })));
                })));
        }
        return (React.createElement("div", { className: "col-md-7 col-xs-12" }, images));
    };
    ProductImages = __decorate([
        mobx_react_1.observer
    ], ProductImages);
    return ProductImages;
}(React.Component));
exports.default = ProductImages;
